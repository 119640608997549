@media screen and (min-width: 768px) {
  .container {
    width: 1000px; } }

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Ropa Sans', sans-serif; }

h1 {
  color: #003468;
  font-size: 29px;
  text-transform: uppercase; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #003468; }

img {
  max-width: 100%; }

@media screen and (min-width: 767px) {
  .carousel-indicators {
    bottom: 150px; } }

.inline-highlight {
  color: #7587aa; }

.btn {
  border-radius: 0; }

.btn-primary {
  background-color: #589499;
  border-color: #589499;
  text-transform: uppercase; }

.btn-transparent {
  border-radius: 3px;
  border: 1px solid #003468;
  color: #525252;
  padding: 10px 15px;
  max-width: 100%; }

.section-getinvolved .btn-transparent {
  font-size: 12px; }

@media screen and (min-width: 767px) {
  .section-getinvolved .btn-transparent {
    font-size: 24px; } }

.well {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.well li {
  color: #7587aa; }

blockquote {
  border-left: none;
  color: #7587aa;
  font-size: 24px;
  font-style: italic;
  margin-bottom: 10px;
  padding: 15px 0;
  text-align: center; }

blockquote span {
  color: #333; }

.blockquote-alt {
  color: #b9be63;
  line-height: 1.1;
  text-shadow: 0 1px 1px #fff;
  padding: 20px 70px;
  position: relative; }

.blockquote-alt:before,
.blockquote-alt:before {
  font-size: 140px;
  position: absolute; }

.blockquote-alt:before {
  content: "\201C";
  left: 0;
  top: -25px; }

.blockquote-alt:after {
  font-size: 140px;
  bottom: 0;
  content: "\201D";
  right: 0; }

.event-order-form .form-control {
  display: inline-block;
  max-width: 180px; }

.fullwidthbanner-container {
  height: 575px; }

.content-slideshow {
  background-color: #67798F;
  color: #fff;
  min-height: 575px; }

@media screen and (max-width: 767px) {
  .content-slideshow {
    display: none; } }

.content-slideshow .bullets.simplebullets.round {
  bottom: 135px !important;
  opacity: 1 !important; }

.content-slideshow .bullets.simplebullets.round .bullet {
  background-image: none !important;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6) inset;
  height: 12px;
  margin-left: 5px;
  width: 12px; }

.content-slideshow .bullets.simplebullets.round .bullet:first-child {
  margin-left: 0; }

.content-slideshow .bullets.simplebullets.round .bullet.selected {
  background-color: #D3DC4E;
  border: none; }

.content-slideshow .apex-slider-shadow,
.content-slideshow .timer {
  display: none !important; }

.content-slideshow .item {
  height: 575px; }

.content-slideshow .item h2 {
  color: #003468;
  font-size: 28px !important;
  margin: 0 0 6px;
  text-transform: uppercase; }

.content-slideshow .item p {
  color: #6b7ea4;
  font-size: 18px !important;
  line-height: 1.22857143 !important; }

.content-slideshow .item .btn {
  background-color: #8cb6bb;
  border-radius: 0;
  color: #fff;
  font-size: 16px !important;
  margin-top: 13px;
  padding-bottom: 3px;
  text-transform: uppercase; }

.content-slideshow .item .btn:hover {
  background-color: #D3DC4E; }

.content-slideshow .item .caption-bg {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  z-index: 0; }

.content-slideshow .item h2,
.content-slideshow .item p,
.content-slideshow .item .btn {
  z-index: 5; }

.header {
  background-color: #fff;
  border-top: 7px solid #003468;
  min-height: 138px;
  position: relative;
  z-index: 30; }

.header--top-nav {
  background: #003468;
  display: none;
  float: right; }

@media (min-width: 768px) {
  .header--top-nav {
    display: inline-block; } }

.header--top-nav ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0 5px; }

.header--top-nav ul li {
  display: inline-block;
  float: none; }

.header--top-nav ul li a {
  display: inline-block;
  padding: 5px; }

.header--top-nav ul li a:hover {
  opacity: 0.8; }

.social-icons {
  display: block; }

.header-logo {
  padding-top: 20px;
  padding-bottom: 28px; }

@media (max-width: 768px) {
  .header-logo {
    text-align: center; } }

@media (min-width: 768px) {
  .header-logo {
    width: 23.66666667%; } }

.header-nav {
  position: inherit;
  text-transform: uppercase; }

@media (min-width: 768px) {
  .header-nav {
    margin-left: 1.33333333%; } }

@media screen and (max-width: 767px) {
  .header-nav {
    padding: 3px; } }

.header-nav .navbar-toggle {
  background-color: #003468; }

.header-nav .navbar-toggle .icon-bar {
  background-color: #fff; }

.header-nav .nav .open > a,
.header-nav .nav .open > a:hover,
.header-nav .nav .open > a:focus {
  background-color: transparent !important; }

@media (min-width: 768px) {
  .header-nav .navbar {
    margin-bottom: 0;
    position: inherit; }
  .header-nav .navbar-nav {
    margin-top: 18px; }
  .header-nav .navbar-collapse {
    padding-right: 0;
    padding-left: 0; }
  .header-nav .navbar-nav > li > a {
    padding-right: 0;
    padding-left: 0; }
  .header-nav .navbar-nav > li {
    min-height: 86px; }
  .header-nav .navbar-nav > li > a {
    padding: 35px 0 40px; }
  .header-nav .social-icons {
    display: none; } }

.header-nav .navbar-header {
  background-color: #003468; }

.header-nav .dropdown-menu > li > a {
  line-height: 1;
  padding: 0 11px 0 20px; }

.header-nav .nav-wrap li {
  background: transparent url("../../src/images/bg-nav-divider.png") center left no-repeat;
  font-size: 16px;
  font-weight: none;
  margin-left: 20px;
  padding-right: 0;
  padding-left: 20px;
  position: inherit; }

@media (min-width: 768px) {
  .header-nav .nav-wrap li ul {
    background-color: #F0F0F0;
    border-top: 1px solid #e8e8e8;
    left: 0;
    padding: 13px 0;
    position: absolute;
    text-align: center;
    top: 139px;
    width: 100%;
    z-index: 11; } }

.header-nav .nav-wrap li ul li {
  background-image: none;
  font-size: 13px;
  margin-left: 7px;
  padding-left: 10px; }

@media (min-width: 768px) {
  .header-nav .nav-wrap li ul li {
    border-left: 2px solid #ccc;
    display: inline-block;
    position: relative; }
  .header-nav .nav-wrap li ul li:first-child {
    border-left: none; } }

.header-nav .nav-wrap li:hover a {
  background-color: transparent; }

.header-nav .nav-wrap li:first-child {
  background-image: none;
  margin-left: 0;
  padding-left: 0; }

.header-nav .nav-wrap li.inline a {
  display: inline-block; }

.header-subnav {
  display: none;
  background-color: #F0F0F0;
  border-top: 1px solid #e8e8e8;
  padding: 15px 0; }

.header-subnav ul {
  display: none;
  margin: 0;
  padding: 0;
  text-align: center; }

.header-subnav li {
  background-image: none;
  color: #333;
  font-size: 13px;
  line-height: 1;
  margin-left: 7px;
  border-left: 2px solid #ccc;
  display: inline-block;
  position: relative;
  padding-left: 10px;
  text-transform: uppercase; }

.header-subnav li a {
  color: #333;
  line-height: 1;
  padding: 0 11px 0 13px; }

.header-subnav li:first-child {
  border-left: none; }

.content-tiles {
  position: relative;
  z-index: 40; }

@media screen and (min-width: 767px) {
  .content-tiles {
    margin-top: -118px;
    padding-bottom: 65px; } }

.content-tile-wrap {
  padding: 0 5px; }

.content-tile {
  background-color: #d1da3d;
  background-color: rgba(209, 218, 61, 0.85);
  display: block;
  padding: 20px 10px 10px;
  text-align: center;
  text-transform: uppercase; }

@media screen and (max-width: 767px) {
  .content-tile {
    margin-bottom: 20px;
    padding: 20px 0 10px; } }

.content-tile h3,
.content-tile p {
  color: #fff; }

.content-tile p {
  color: #003468;
  font-size: 12px;
  margin-bottom: 0;
  padding-top: 24px; }

.content-tile h3 {
  font-size: 20px;
  line-height: 1; }

@media screen and (min-width: 767px) {
  .content-tile h3 {
    font-size: 24px; } }

.content-tile h3 span {
  color: #003468; }

.content-tile:hover {
  text-decoration: none; }

.content-tile-second {
  background-color: #aab12e;
  background-color: rgba(170, 177, 46, 0.85); }

.content-tile-third {
  background-color: #b6dfe2;
  background-color: rgba(182, 223, 226, 0.85); }

.content-tile-fourth {
  background-color: #78a5aa;
  background-color: rgba(120, 165, 170, 0.85); }

.content-top {
  height: 155px;
  text-align: center; }

.content-top h1,
.content-top h3 {
  color: #fff;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3); }

@media screen and (max-width: 767px) {
  .content-top h1,
  .content-top h3 {
    line-height: inherit; } }

.content-top h1 {
  font-size: 37px;
  letter-spacing: 3px;
  line-height: 165px; }

.content-top h3 {
  font-size: 25px;
  margin: 0; }

.content-top a {
  color: #fff;
  text-decoration: underline; }

.content-top.content-top-footer {
  height: auto;
  padding: 35px; }

.content-top.content-top-entry-regatta h1 {
  line-height: inherit; }

.content-wrap {
  color: #333;
  margin-top: 30px;
  padding-bottom: 20px; }

.content-wrap h1 {
  margin-top: 0;
  margin-bottom: 19px;
  padding-bottom: 9px; }

.content-wrap p {
  color: #333; }

.content-wrap-feature {
  background-color: #CCE0E4;
  padding: 35px 0; }

@media screen and (min-width: 767px) {
  .contact-table-col {
    border-left: 1px solid #ccc;
    min-height: 400px; } }

.contact-list {
  border: 1px solid #ccc; }

.contact-list td,
.contact-list th {
  padding: 15px 37px; }

.contact-list td {
  border: 1px solid #ccc; }

.contact-list th {
  border: 1px solid #ccc;
  color: #7DAFB4;
  text-transform: uppercase;
  vertical-align: top; }

// .contact-list .contact-list-phone {
//   background: #EBF6F8 url(../../src/images/icon-phone.png) 10px 15px no-repeat; }

.contact-list h4,
.contact-list p {
  color: #333;
  margin: 0; }

.contact-list h4 {
  font-weight: bold;
  text-transform: uppercase; }

// .contact-list .contact-list-fax {
//   background: #EBF6F8 url(../../src/images/icon-fax.png) 10px 15px no-repeat;
//   padding-left: 37px; }

// .contact-list .contact-list-email {
//   background: #EBF6F8 url(../../src/images/icon-email.png) 10px 15px no-repeat;
//   padding-left: 37px; }

// .contact-list .contact-list-address {
//   background: #EBF6F8 url(../../src/images/icon-location.png) 10px 15px no-repeat;
//   padding-left: 37px; }

.partner-list {
  margin: 35px 0; }

.partner-list-item {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 35px;
  text-align: center; }

.partner-list-item h4 {
  font-size: 18px;
  margin-bottom: 20px; }

.partner-list-item-ihibi {
  padding-bottom: 35px; }

.partner-list-item-ihibi .btn {
  margin-top: 47px; }

.partner-list-item-tri img {
  margin-top: -18px; }

.partner-list-item-tri .btn {
  margin-top: 24px; }

.team-list {
  margin-top: 14px; }

.team-list h5 {
  background-color: #6085A3;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  text-transform: uppercase; }

.join-us-contact {
  color: #fff; }

@media screen and (max-width: 767px) {
  .join-us-contact {
    text-align: center; } }

.join-us-contact img {
  padding-right: 15px; }

@media screen and (min-width: 767px) {
  .join-us-contact .join-us-contact-item {
    border: 1px solid #fff;
    display: inline-block;
    padding: 5px 10px; } }

.join-us-contact .join-us-contact-item a {
  color: #fff; }

.join-us-contact span {
  padding: 0 40px; }

@media screen and (max-width: 767px) {
  .join-us-contact span {
    display: block;
    padding: 0; } }

.content-cancer-ageing-info {
  background-color: #E1E584;
  margin-top: 60px;
  margin-bottom: 100px;
  padding: 25px 0 50px; }

.content-cancer-ageing-info p {
  text-shadow: 0 1px 0 #fff; }

@media screen and (min-width: 767px) {
  .content-cancer-ageing-info p {
    margin-bottom: 0; } }

.content-cancer-ageing-info img {
  display: block;
  margin: 0 auto 15px auto;
  width: 67px; }

@media screen and (min-width: 767px) {
  .content-cancer-ageing-middle p {
    border-right: 1px solid #7C8035;
    border-left: 1px solid #7C8035;
    padding: 0 25px; } }

.our-mission-info {
  background-color: #589499;
  padding: 45px 0 55px;
  margin: 0 0 90px; }

.our-mission-info img {
  margin-right: 30px; }

.our-mission-info ul {
  padding-left: 55px; }

.our-mission-info p,
.our-mission-info h3,
.our-mission-info li {
  color: #fff; }

.our-mission-video {
  background-color: #ECECEC;
  margin-top: 10px;
  padding: 26px 0 30px 0;
  text-align: center; }

.our-mission-video h3 {
  font-size: 20px;
  margin-bottom: 32px;
  text-transform: uppercase; }

@media screen and (max-width: 768px) {
  .our-mission-video iframe {
    width: 100%; } }

.career-locations .btn-secondary {
  display: inline-block;
  background-color: #C2C866;
  color: #fff; }

// .content-hdr {
//   background: #589499 url(../../src/images/careers-hdr.jpg) top center no-repeat;
//   padding: 60px 0;
//   margin-bottom: 80px; }

.content-hdr h1 {
  margin-bottom: 28px;
  text-align: center; }

.content-hdr p,
.content-hdr h1 {
  color: #fff; }

.content-why-hssb1 {
  background-color: #E7F5F6;
  color: #60949a;
  padding: 45px 0; }

.content-why-ca-callout {
  background-color: #DBE16E;
  text-align: center; }

.content-why-ca-callout h1 {
  color: #525252;
  font-size: 30px;
  padding: 25px 0;
  text-transform: capitalize; }

.content-why-ca-footer {
  margin-bottom: 60px; }

.content-why-ca-footer a {
  margin-top: 20px;
  position: relative;
  top: 20px; }

.gi-grid {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center; }

.gi-grid img {
  margin-bottom: 30px; }

.gi-infographic {
  background: #6DADB2 url(../../src/images/gi-infographic.jpg) top center no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 15px; }

.gi-infographic h1,
.gi-infographic p {
  color: #fff; }

.gi-infographic img {
  padding-bottom: 30px; }

.gi-give {
  background-color: #6DADB2;
  padding: 40px 0; }

.gi-give h1,
.gi-give p {
  color: #fff; }

.gi-give h4,
.gi-give ul li {
  color: #B4CA7A; }

.gi-give h4 {
  margin-bottom: 20px; }

.gi-give ul li {
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: 0; }

.gi-give .btn-transparent {
  border-color: #fff;
  color: #fff;
  font-size: 20px;
  margin: 50px auto 0 auto;
  text-align: center;
  width: 550px; }

.gi-give .btn-transparent a {
  color: #fff; }

.centered-list {
  margin-top: 30px;
  padding-left: 0; }

.centered-list li {
  color: #589499;
  list-style-type: none;
  margin-bottom: 10px; }

.centered-list a,
.centered-list h4 {
  color: #589499; }

.centered-list h4 {
  margin-bottom: 0; }

.sponsor-donate {
  background: #AEB358 url(../../src/images/sponsor-donate-bg.jpg) top center no-repeat;
  margin-bottom: 50px;
  padding-top: 40px; }

.footer {
  color: #003468;
  font-size: 12px; }

.footer-main {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-top: 25px;
  padding-bottom: 25px; }

.sponsor-slider .slick-track {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.sponsor-slider img.slick-slide {
  padding: 0 15px; }

@media screen and (min-width: 767px) {
  .footer-logo {
    padding-right: 0;
    width: 14.666667%; }
  .footer-logo img {
    max-width: 87%; } }

.footer-contact {
  font-size: 14px; }

@media screen and (min-width: 767px) {
  .footer-contact {
    background: transparent url("../../src/images/bg-nav-divider.png") center left no-repeat;
    padding-left: 2.5%;
    width: 35.333333%; } }

@media screen and (min-width: 767px) {
  .footer-partners {
    background: transparent url("../../src/images/bg-nav-divider.png") center left no-repeat;
    padding-left: 3%;
    padding-right: 0;
    width: 50%; } }

@media screen and (max-width: 767px) {
  .footer-partners {
    overflow: hidden;
    padding-top: 15px; } }

.footer-partners .footer-partners-list {
  text-align: center; }

.footer-partners .footer-partners-list img {
  max-width: 100%; }

.footer-partners .footer-partners-list-last {
  padding-top: 15px; }

.footer-partners .footer-partners-list-cc img {
  margin-top: 5px; }

.footer-partners h6 {
  color: #003468;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase; }

.footer-partners img {
  vertical-align: middle; }

.footer-copirite {
  color: #66675F;
  font-family: arial, sans-serif;
  font-size: 10px;
  text-align: right;
  padding: 10px 0; }

.news-sidebar {
  font-size: 14px; }

.news-sidebar h4 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 6px; }

.news-sidebar ul {
  margin: 0 0 80px 0;
  padding: 0; }

.news-sidebar li {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }

.news-item {
  border-bottom: 1px solid #ccc;
  margin-bottom: 80px; }

.news-item h3 {
  font-size: 24px; }

.news-item-footer {
  color: #8f9ec1;
  font-size: 14px;
  line-height: 1; }

.news-item-footer a {
  color: #8f9ec1; }

.news-item-actions {
  padding-bottom: 15px;
  text-align: right; }

.news-item-actions ul {
  margin-bottom: 0; }

.news-item-actions li {
  display: inline-block;
  list-style-type: none; }

.news-item-actions li:first-child {
  border-right: 2px solid #8f9ec1;
  margin-right: 5px;
  padding-right: 10px; }

.gi-infographic-small {
  background-color: #589499;
  padding-bottom: 30px; }

.gi-infographic-small a {
  color: #ffffff;
  text-decoration: underline; }

.gi-infographic-small p {
  color: #ffffff; }

.gi-infographic-small li {
  color: #DEE36E; }

.gi-infographic-small ul {
  padding-left: 20px; }

.gi-infographic-small h1 {
  color: #ffffff;
  text-align: center; }

.gi-infographic-small h3 {
  color: #DEE36E;
  text-align: center; }

.gi-infographic-small .info_box {
  border: 1px dashed #ffffff;
  margin-top: 65px;
  padding: 15px;
  padding-top: 15px;
  position: relative; }

.gi-infographic-small .box_details {
  border: 1px solid #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  padding: 10px;
  margin-bottom: 10px; }

.gi-infographic-small .center {
  text-align: center; }

.icon_container {
  text-align: center;
  position: absolute;
  top: -40px;
  width: 100%;
  margin-left: -15px; }

.icon_container img.involve_icon {
  width: 66px;
  height: 65px; }

img.email_icon {
  width: 25px; }

img.phone_icon {
  width: 23px; }

a.email {
  text-decoration: none; }

@media screen and (min-width: 767px) {
  .small-download-btn {
    display: block;
    margin: 0 auto;
    width: 300px; } }
