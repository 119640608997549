//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  padding: $jumbotron-padding;
  margin-bottom: $jumbotron-padding;
  font-size: ($font-size-base * 1.5);
  font-weight: 200;
  line-height: ($line-height-base * 1.5);
  color: $jumbotron-color;
  background-color: $jumbotron-bg;

  h1 {
    line-height: 1;
    color: $jumbotron-heading-color;
  }
  p {
    line-height: 1.4;
  }

  .container & {
    border-radius: $border-radius-large; // Only round corners at higher resolutions if contained in a container
  }

  @media screen and (min-width: $screen-tablet) {
    padding-top:    ($jumbotron-padding * 1.6);
    padding-bottom: ($jumbotron-padding * 1.6);

    .container & {
      padding-left:  ($jumbotron-padding * 2);
      padding-right: ($jumbotron-padding * 2);
    }

    h1 {
      font-size: ($font-size-base * 4.5);
    }
  }
}
