/*!
 * Bootstrap v3.0.0
 *
 * Copyright 2013 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world by @mdo and @fat.
 */

// Core variables and mixins
@import "./bootstrap/variables";
@import "./bootstrap/mixins";

$brand-primary:         #003468;
$brand-tertiary:        #D3DC4E;

$font-size-base:          16px;

$headings-color:          $brand-tertiary;

$container-lg-desktop: 1000px;
$container-lg: $container-lg-desktop;
$container-desktop: 1000px;
$container-md: $container-desktop;
$container-tablet: 768px;

$tablet-portrait: 767px;

// // Reset
@import "./bootstrap/normalize";
@import "./bootstrap/print";

// // Core CSS
@import "./bootstrap/scaffolding";
@import "./bootstrap/type";
@import "./bootstrap/code";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";

// // Components
@import "./bootstrap/component-animations";
@import "./bootstrap/glyphicons";
@import "./bootstrap/dropdowns";
@import "./bootstrap/button-groups";
@import "./bootstrap/input-groups";
@import "./bootstrap/navs";
@import "./bootstrap/navbar";
@import "./bootstrap/breadcrumbs";
@import "./bootstrap/pagination";
@import "./bootstrap/pager";
@import "./bootstrap/labels";
@import "./bootstrap/badges";
@import "./bootstrap/jumbotron";
@import "./bootstrap/thumbnails";
@import "./bootstrap/alerts";
@import "./bootstrap/progress-bars";
@import "./bootstrap/media";
@import "./bootstrap/list-group";
@import "./bootstrap/panels";
@import "./bootstrap/wells";
@import "./bootstrap/close";

// // Components w/ JavaScript
@import "./bootstrap/modals";
@import "./bootstrap/tooltip";
@import "./bootstrap/popovers";
@import "./bootstrap/carousel";

// // Utility classes
@import "./bootstrap/utilities";
@import "./bootstrap/responsive-utilities";

@import "./theme";
@import "./carp";

@import "./apex-slider.css";

